import React from "react"
import { PageProps } from "gatsby"

import SEO from "../../components/seo"

import { GridContainer } from "../../components/grid"
import { SecondaryHero } from "../../components/heros"

const PaymentFailedPage: React.FC<PageProps> = () => (
  <>
    <SEO title="Betaling Mislukt" />
    <GridContainer>
      <SecondaryHero
        title="De betaling is mislukt"
        subtitle="Probeer het later nog eens."
      ></SecondaryHero>
    </GridContainer>
  </>
)

export default PaymentFailedPage
